import { useEffect, useRef, useState } from 'react';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import { mapPosition } from 'utils/constants';

const Map = () => {
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    const google = window.google;
    const zoom = isMobile ? 12 : 14;
    const map = new google.maps.Map(mapRef.current, {
      center: mapPosition.area,
      zoom,
    });
    setMap(map);
  }, [isMobile]);

  useEffect(() => {
    new google.maps.Marker({
      position: mapPosition.marker,
      map,
    });
  }, [map]);
  return <Box h='full' borderRadius='lg' ref={mapRef} />;
};

export default Map;
