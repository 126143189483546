import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { MdGTranslate } from 'react-icons/md';
import { BsTranslate } from 'react-icons/bs';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import { useTranslation } from 'react-i18next';

//declaration of animation variants
const variants: Variants = {
  initial: {
    y: -20,
    opacity: 0,
    transition: {
      type: 'spring',
      duration: 0.2,
    },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      duration: 0.2,
    },
  },
  exit: {
    y: 20,
    opacity: 0,
    transition: {
      type: 'spring',
      duration: 0.2,
    },
  },
};

const LanguageSwitch = () => {
  const { i18n } = useTranslation();
  const MotionButton = motion<IconButtonProps>(IconButton);

  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      <MotionButton
        mx={2}
        variants={variants}
        initial='initial'
        animate='animate'
        exit='exit'
        icon={i18n.language === 'us' ? <BsTranslate /> : <MdGTranslate />}
        aria-label='Language'
        onClick={() => {
          i18n.language === 'us'
            ? i18n.changeLanguage('fr')
            : i18n.changeLanguage('us');
        }}
      />
    </AnimatePresence>
  );
};

export default LanguageSwitch;
