const components = {
  Button: {
    // The base styles for each part
    baseStyle: {
      fontFamily: 'Gotu',
      color: 'text',
      borderRadius: 8,
      _hover: {
        textDecoration: 'none',
        bg: 'btnBg',
        _disabled: {
          bg: 'brand.500',
        },
      },
      _active: { bg: 'brand.300' },
    },
    // The size styles for each part
    sizes: {},
    // The variant styles for each part
    variants: {
      default: {},
      active: {
        bg: 'btnBg',
      },
      transparent: {},
    },
    // The default `size` or `variant` values
    defaultProps: {},
  },
  Heading: {
    baseStyle: {
      fontFamily: 'Gotu',
      color: 'text',
    },
    variants: {},
    defaultProps: {
      size: '',
    },
  },
  Text: {
    baseStyle: {
      fontFamily: 'Gotu',
      color: 'text',
    },
    variants: {},
    defaultProps: {
      fontFamily: 'Gotu',
      color: 'text',
    },
  },
};

export default components;
