import { Flex, Button, Center, Heading, Text } from '@chakra-ui/react';
import NextLink from 'next/link';
import { useTranslation } from 'react-i18next';

const Hero = () => {
  const { t } = useTranslation();

  return (
    <Center
      h='100vh'
      w='full'
      bgImage={{ base: null, md: 'url(/images/hero4.jpg)' }}
      bgAttachment='fixed'
      bgPosition='center'
      bgSize={{ base: 'contain', md: 'cover' }}
      bgRepeat='no-repeat'
      pos='relative'
    >
      <Flex
        direction='column'
        justifyContent='center'
        alignItems='center'
        pos='absolute'
        bottom={36}
        py={10}
        px={2}
      >
        <Heading
          as='h1'
          fontSize={'4xl'}
          fontWeight={'bold'}
          textAlign='center'
          color={{ md: 'white' }}
          px={8}
        >
          {t('featureSection.header')}
        </Heading>
        <Text py={10} px={{ base: 4, md: 36 }} color={{ md: 'white' }}>
          {t('featureSection.subheader')}
        </Text>
        <NextLink passHref href={'/health-and-safety-protocols'}>
          <Button
            colorScheme='red'
            size='md'
            variant='solid'
            textAlign={'center'}
          >
            {t('featureSection.protocolButton')}
          </Button>
        </NextLink>
      </Flex>
    </Center>
  );
};

export default Hero;
