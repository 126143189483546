import { ReactNode } from 'react';
import {
  Box,
  chakra,
  Container,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
  ScaleFade,
} from '@chakra-ui/react';
import { FaFacebook, FaInstagram, FaBars } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import Logo from './Logo';

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <ScaleFade initialScale={1} whileHover={{ scale: 1.3 }} in>
      <chakra.button
        bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
        rounded={'full'}
        w={8}
        h={8}
        cursor={'pointer'}
        as={'a'}
        href={href}
        display={'inline-flex'}
        alignItems={'center'}
        justifyContent={'center'}
        transition={'background 0.3s ease'}
        _hover={{
          bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
        }}
      >
        <VisuallyHidden>{label}</VisuallyHidden>
        {children}
      </chakra.button>
    </ScaleFade>
  );
};

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Box w='full'>
      <Container
        as={Stack}
        maxW={'8xl'}
        py={4}
        px={{ base: 2, md: 24 }}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}
      >
        <Logo />
        <Text>{t('footer.legal')}</Text>
        <Box>
          <Stack direction={'row'} spacing={6}>
            <SocialButton
              label={'Facebook'}
              href={'https://www.facebook.com/Maisie-Beauty-112111624825991'}
            >
              <FaFacebook />
            </SocialButton>
            <SocialButton
              label={'Instagram'}
              href={'https://www.instagram.com/maisiebeautyspa/'}
            >
              <FaInstagram />
            </SocialButton>
            <SocialButton
              label={'Fresha'}
              href={
                'https://www.fresha.com/a/maisie-beauty-coquitlam-3502-princeton-avenue-y6fltmj6?pId=660399'
              }
            >
              <FaBars />
            </SocialButton>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
