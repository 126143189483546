import { useRef } from 'react';
import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  useBreakpointValue,
  List,
  ListItem,
  ListIcon,
  Button,
  ScaleFade,
  SlideFade,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useInViewport } from 'react-in-viewport';
import { FaCheckCircle } from 'react-icons/fa';
import NextLink from 'next/link';
import { freshaLink } from 'utils/constants';

type PriceWrapperProps = {
  name: string;
  price?: string;
  duration?: string;
  items: string[];
  buttonLabel: string;
  link: string;
  denote?: string;
};

const PriceWrapper = ({
  name,
  price,
  duration,
  items,
  buttonLabel,
  link,
  denote,
}: PriceWrapperProps) => {
  const ref = useRef(null);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const rootMargin = isMobile ? '0px' : '-200px';
  const { inViewport, enterCount } = useInViewport(
    ref,
    { rootMargin },
    { disconnectOnLeave: false },
    {}
  );
  const denoteTextBg = useColorModeValue('yellow', 'yellow.600');
  const denoteTextColor = useColorModeValue('gray.900', 'gray.300');
  const cardHeadBg = useColorModeValue('brand.200', 'brand.1100');
  const cardBodyBg = useColorModeValue('brand.100', 'gray.1200');
  const checkMarkColor = useColorModeValue('red.500', 'yellow.500');

  return (
    <SlideFade in={enterCount > 0} offsetY='300px'>
      <ScaleFade initialScale={1} whileHover={{ scale: 1.1 }} in={inViewport}>
        <Box
          ref={ref}
          mb={4}
          mx={2}
          shadow='base'
          borderWidth='1px'
          alignSelf={{ base: 'center', lg: 'flex-start' }}
          borderColor={useColorModeValue('gray.200', 'gray.500')}
          borderRadius={'xl'}
        >
          <Box position='relative'>
            {denote && (
              <Box
                position='absolute'
                top='-16px'
                left='50%'
                style={{ transform: 'translate(-50%)' }}
              >
                <Text
                  textTransform='uppercase'
                  bg={denoteTextBg}
                  px={3}
                  py={1}
                  color={denoteTextColor}
                  fontSize='sm'
                  fontWeight='600'
                  rounded='xl'
                >
                  {denote}
                </Text>
              </Box>
            )}
            <Box bg={cardHeadBg} py={4} px={12} borderTopRadius={'xl'}>
              <Text fontWeight='500' fontSize='2xl'>
                {name}
              </Text>
              <HStack justifyContent='center'>
                {price && (
                  <>
                    <Text fontSize='3xl' fontWeight='600'>
                      $
                    </Text>
                    <Text fontSize='5xl' fontWeight='900'>
                      {price}
                    </Text>
                  </>
                )}
                {duration && (
                  <Text fontSize='3xl' color='gray.500'>
                    /{duration}
                  </Text>
                )}
              </HStack>
            </Box>
            <VStack bg={cardBodyBg} py={4} borderBottomRadius={'xl'}>
              <List spacing={3} textAlign='start' px={12}>
                {items.map((item, index) => (
                  <ListItem key={index.toString()}>
                    <ListIcon as={FaCheckCircle} color={checkMarkColor} />
                    {item}
                  </ListItem>
                ))}
              </List>
              <Box w='80%' pt={7}>
                <NextLink href={link} passHref>
                  <Button w='full' colorScheme='red'>
                    {buttonLabel}
                  </Button>
                </NextLink>
              </Box>
            </VStack>
          </Box>
        </Box>
      </ScaleFade>
    </SlideFade>
  );
};

const MembershipSection = () => {
  const { t } = useTranslation();
  const packages = [
    {
      name: t('membership.card1.heading'),
      price: null,
      duration: null,
      items: [
        t('membership.card1.item1'),
        t('membership.card1.item2'),
        t('membership.card1.item3'),
      ],
      buttonLabel: t('membership.card1.buttonLabel'),
      link: freshaLink.everything,
      denote: t('membership.card1.denote'),
    },
    {
      name: t('membership.card2.heading'),
      price: t('membership.card2.price'),
      duration: t('membership.month'),
      items: [
        t('membership.card2.item1'),
        t('membership.card2.item2'),
        t('membership.card2.item3'),
        // t('membership.card2.item4'),
      ],
      buttonLabel: t('membership.card2.buttonLabel'),
      link: freshaLink.deluxeMembership,
      // denote: t('membership.card2.denote'),
    },
    {
      name: t('membership.card3.heading'),
      price: t('membership.card3.price'),
      duration: t('membership.month'),
      items: [
        t('membership.card3.item1'),
        t('membership.card3.item2'),
        t('membership.card3.item3'),
        // t('membership.card3.item4'),
      ],
      buttonLabel: t('membership.card3.buttonLabel'),
      link: freshaLink.starterMembership,
      denote: null,
    },
  ];

  return (
    <Box id='membership' py={12}>
      <VStack spacing={2} textAlign='center'>
        <Heading as='h1' fontSize='4xl'>
          {t('membership.heading')}
        </Heading>
        <Text fontSize='lg' color={'gray.500'}>
          {t('membership.subheading')}
        </Text>
      </VStack>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        textAlign='center'
        justify='center'
        spacing={{ base: 4, lg: 10 }}
        pt={16}
      >
        {packages.map(
          (
            { name, price, duration, items, buttonLabel, link, denote },
            index
          ) => (
            <PriceWrapper
              key={index.toString()}
              name={name}
              price={price}
              duration={duration}
              items={items}
              buttonLabel={buttonLabel}
              link={link}
              denote={denote}
            />
          )
        )}
      </Stack>
    </Box>
  );
};

export default MembershipSection;
