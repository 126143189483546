import { useRef } from 'react';
import {
  Box,
  Image,
  SimpleGrid,
  Text,
  Stack,
  SlideFade,
  ScaleFade,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import NextLink from 'next/link';
import { useInViewport } from 'react-in-viewport';

interface FeatureProps {
  title: string;
  text: string;
  imgUrl: string;
  link: {
    path: string;
    text: string;
  };
}

const Feature = ({ title, text, imgUrl, link }: FeatureProps) => {
  const featRef = useRef(null);
  const { enterCount } = useInViewport(
    featRef,
    {},
    { disconnectOnLeave: false },
    {}
  );

  return (
    <Stack ref={featRef}>
      <NextLink passHref href={link.path}>
        <Box
          alignSelf='center'
          _hover={{
            textDecoration: 'none',
            cursor: 'pointer',
          }}
        >
          <ScaleFade
            initialScale={1}
            whileHover={{ scale: 1.1 }}
            in={enterCount > 0}
          >
            <Image
              alignSelf='center'
              alignContent='center'
              src={imgUrl}
              alt='feature'
              boxSize='80'
              objectFit='cover'
              filter={useColorModeValue(
                'invert(4%) sepia(5%) saturate(5357%) hue-rotate(201deg) brightness(93%) contrast(88%)',
                'invert(91%) sepia(5%) saturate(649%) hue-rotate(4deg) brightness(113%) contrast(96%)'
              )}
            />
          </ScaleFade>
        </Box>
      </NextLink>
      <Text fontWeight={600}>{title}</Text>
      <Text>{text}</Text>
    </Stack>
  );
};

const FeatureSection = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const rootMargin = isMobile ? '0px' : '-200px';
  const { enterCount } = useInViewport(
    ref,
    { rootMargin },
    { disconnectOnLeave: false },
    {}
  );
  const Links = [
    {
      path: '/contact',
      text: 'Contact us Now',
    },
    {
      path: '/service',
      text: 'See our services',
    },
    {
      path: '#membership',
      text: 'Check our memberships and promotions',
    },
  ];

  return (
    <Box ref={ref} px={{ base: 4, sm: 12, md: 16 }}>
      <SlideFade in={enterCount > 0} offsetY='300px'>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
          <Feature
            title={t('featureSection.title1')}
            text={t('featureSection.text1')}
            imgUrl={'/images/feature_facial.svg'}
            link={Links[0]}
          />
          <Feature
            title={t('featureSection.title2')}
            text={t('featureSection.text2')}
            imgUrl={'/images/feature_product.svg'}
            link={Links[1]}
          />
          <Feature
            title={t('featureSection.title3')}
            text={t('featureSection.text3')}
            imgUrl={'/images/feature_membership.svg'}
            link={Links[2]}
          />
        </SimpleGrid>
      </SlideFade>
    </Box>
  );
};

export default FeatureSection;
