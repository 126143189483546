import {
  IconButton,
  IconButtonProps,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import { FiSun, FiMoon } from 'react-icons/fi';
import { AnimatePresence, motion, Variants } from 'framer-motion';

//declaration of animation variants
const variants: Variants = {
  initial: {
    y: -20,
    opacity: 0,
    transition: {
      type: 'spring',
      duration: 0.2,
    },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      duration: 0.2,
    },
  },
  exit: {
    y: 20,
    opacity: 0,
    transition: {
      type: 'spring',
      duration: 0.2,
    },
  },
};

const DarkModeSwitch = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const MotionButton = motion<IconButtonProps>(IconButton); // declaration for the IconButton component with props of a motion
  const isDark = colorMode === 'dark';

  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      <MotionButton
        variants={variants}
        initial='initial'
        animate='animate'
        exit='exit'
        icon={isDark ? <FiSun /> : <FiMoon />}
        aria-label='Toggle Theme'
        colorScheme={useColorModeValue('purple', 'orange')}
        onClick={toggleColorMode}
      />
    </AnimatePresence>
  );
};

export default DarkModeSwitch;
