export const siteTitle = 'Maisie Beauty';
export const mapPosition = {
  area: { lat: 49.288782, lng: -122.7745595 },
  marker: {
    lat: 49.2968,
    lng: -122.74455,
  },
};
export const contactInfo = {
  phone: '+1 (604) 445 9249',
  email: 'maisiebeautysalon@gmail.com',
  address: '3502 Princeton Ave, Coquitlam',
};

export const freshaLink = {
  everything:
    'https://www.fresha.com/book-now/maisie-beauty-wxun6ef8/all-offer?pId=660399',
  allServices:
    'https://www.fresha.com/book-now/maisie-beauty-wxun6ef8/services?lid=703267&eid=1878051&pId=660399',
  voucher:
    'https://www.fresha.com/book-now/maisie-beauty-wxun6ef8/vouchers?pId=660399',
  starterMembership:
    'https://www.fresha.com/book-now/maisie-beauty-wxun6ef8/paid-plans?id=57814&pId=660399',
  deluxeMembership:
    'https://www.fresha.com/book-now/maisie-beauty-wxun6ef8/paid-plans?id=57813&pId=660399',
  expressFacial:
    'https://www.fresha.com/book-now/maisie-beauty-wxun6ef8/services?lid=703267&eid=1878051&oiid=sv%3A10095157&pId=660399',
  premiumFacial:
    'https://www.fresha.com/book-now/maisie-beauty-wxun6ef8/services?lid=703267&eid=1878051&oiid=sv%3A10095425&pId=660399',
  luxuryFacial:
    'https://www.fresha.com/book-now/maisie-beauty-wxun6ef8/services?lid=703267&eid=1878051&oiid=sv%3A10095434&pId=660399',
  guaShaFacial:
    'https://www.fresha.com/book-now/maisie-beauty-wxun6ef8/services?lid=703267&eid=1878051&oiid=sv%3A10096658&pId=660399',
};

export const service = {
  express: {
    price: 45,
    sale: 30,
  },
  premium: {
    price: 75,
    sale: 40,
  },
  luxury: {
    price: 90,
    sale: 50,
  },
  signature: {
    price: 110,
    sale: 60,
  },
};

export const membership = {
  deal: {
    price: null,
  },
  deluxe: {
    price: 179,
  },
  starter: {
    price: 99,
  },
};
