import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import components from './components';

const fonts = { mono: `'Gotu', monospace` };

const breakpoints = createBreakpoints({
  sm: '40em',
  md: '52em',
  lg: '64em',
  xl: '80em',
  '2xl': '96em',
});

const theme = extendTheme({
  styles: {
    global: (props) => ({
      body: {
        bgGradient: 'linear(to-l, bgGradientStart, bgGradientEnd)',
      },
    }),
  },
  semanticTokens: {
    colors: {
      text: {
        default: '#16161D',
        _dark: '#f9FAEA',
      },
      btnBg: {
        default: '#EE2A7B',
        _dark: 'grey.500',
      },
      bgGradientStart: {
        default: 'brand.300',
        _dark: 'brand.1100',
      },
      bgGradientEnd: {
        default: 'brand.200',
        _dark: 'brand.1200',
      },
    },
  },
  colors: {
    brand: {
      100: '#f9faea',
      200: '#faf3e6',
      300: '#f5d7cd',
      400: '#c5c5c9',
      500: '#dde8e4',
      600: '#e9f3ee',
      700: '#f9f5f8',
      800: '#e9f0f3',
      900: '#e5e7ef',
      1000: '#16161D',
      1100: '#191919',
      1200: '#2E2B2D',
    },
    success: {
      100: '#E8F9E7',
      200: '#C9E9C8',
      300: '#AED6AA',
      400: '#6DAB64',
      500: '#558E4B',
      600: '#397031',
      700: '#27571F',
      800: '#1C4614',
    },
    link: {
      600: '#E2EFFF',
      700: '#C6DDFF',
    },
    white: {
      inactive: 'rgba(255, 255, 255, 0.4)',
      active: 'rgba(255, 255, 255, 1.0)',
    },
    black: '#191919',
    blue: {
      500: '#00AEEF',
    },
    red: {
      500: '#EE2A7B',
    },
    yellow: {
      500: '#F7941D',
    },
    grey: {
      100: '#FAFAFA',
      200: '#E6E6E6',
      300: '#C0C0C0',
      400: '#A1A1A1',
      500: '#757575',
      600: '#676767',
      700: '#3B3B3B',
      800: '#1A1A1A',
    },
  },
  fonts,
  breakpoints,
  components,
});

export default theme;
