import React, { useState } from 'react';
import {
  Box,
  Heading,
  Text,
  IconButton,
  Button,
  VStack,
  HStack,
  Wrap,
  WrapItem,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import { MdPhone, MdEmail, MdLocationOn, MdOutlineEmail } from 'react-icons/md';
import { BsFacebook, BsInstagram, BsPerson } from 'react-icons/bs';
import { FaBars } from 'react-icons/fa';
import NextLink from 'next/link';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { contactInfo } from 'utils/constants';

const ContactForm = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const [captchaToken, setCaptchaToken] = useState(null);

  const validateRequiredField = (name, value) => {
    let error;
    if (!value) {
      error = `${name} is required`;
    }
    return error;
  };

  const validateEmail = (value) => {
    let error;
    if (!value) {
      error = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      error = 'Invalid email address';
    }
    return error;
  };

  const sendEmail = (templateParams) => {
    if (captchaToken) {
      emailjs
        .send(
          process.env.NEXT_PUBLIC_EMAIL_SERVICE_ID,
          process.env.NEXT_PUBLIC_EMAIL_TEMPLATE_ID,
          { ...templateParams, 'g-recaptcha-response': captchaToken },
          process.env.NEXT_PUBLIC_EMAIL_PUBLIC_KEY
        )
        .then(
          (result) => {
            if (result.text.toLowerCase() === 'ok') {
              toast({
                title: 'Your message has been sent.',
                description: 'We will get back to you shortly. Thank you!',
                status: 'success',
                duration: 9000,
                isClosable: true,
              });
            }
          },
          (error) => {
            toast({
              title: 'Error! Something went wrong.',
              description: `Please try again at a later time. ${error.text}`,
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          }
        );
    } else {
      toast({
        title: 'Please check reCaptcha and try again',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      bgColor={useColorModeValue('brand.700', 'brand.1200')}
      borderRadius='lg'
      m={{ sm: 4, md: 16, lg: 10 }}
      p={{ sm: 5, md: 5, lg: 16 }}
    >
      <Box p={4}>
        <Wrap spacing={{ base: 20, sm: 3, md: 5, lg: 20 }}>
          <WrapItem>
            <Box>
              <Heading textAlign='center'>{t('contact.form.heading')}</Heading>
              <Text
                mt={{ sm: 3, md: 3, lg: 5 }}
                textAlign='center'
                color='gray.500'
              >
                {t('contact.form.subheading')}
              </Text>
              <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                <VStack pl={0} spacing={3}>
                  <NextLink href={`tel:${contactInfo.phone}`} passHref>
                    <Button
                      size='md'
                      height='48px'
                      width='300px'
                      justifyContent='flex-start'
                      variant='ghost'
                      _hover={{ border: '2px solid #1C6FEB' }}
                      leftIcon={<MdPhone size='20px' />}
                    >
                      {contactInfo.phone}
                    </Button>
                  </NextLink>
                  <NextLink href={`mailto:${contactInfo.email}`} passHref>
                    <Button
                      size='md'
                      height='48px'
                      width='300px'
                      justifyContent='flex-start'
                      variant='ghost'
                      _hover={{ border: '2px solid #1C6FEB' }}
                      leftIcon={<MdEmail color='' size='20px' />}
                    >
                      {contactInfo.email}
                    </Button>
                  </NextLink>
                  <NextLink href='#map' passHref>
                    <Button
                      size='md'
                      height='48px'
                      width='300px'
                      justifyContent='flex-start'
                      variant='ghost'
                      _hover={{ border: '2px solid #1C6FEB' }}
                      leftIcon={<MdLocationOn color='' size='20px' />}
                    >
                      {contactInfo.address}
                    </Button>
                  </NextLink>
                </VStack>
              </Box>
              <HStack
                mt={{ lg: 10, md: 10 }}
                spacing={5}
                px={5}
                alignItems='flex-start'
              >
                <NextLink
                  href='https://www.fresha.com/book-now/maisie-beauty-wxun6ef8/services?lid=703267&eid=1878051&pId=660399'
                  passHref
                >
                  <IconButton
                    aria-label='facebook'
                    variant='ghost'
                    size='lg'
                    isRound={true}
                    _hover={{ bg: '#DCE2FF' }}
                    icon={<BsFacebook size='28px' />}
                  />
                </NextLink>
                <NextLink
                  href='https://www.instagram.com/maisiebeautyspa/'
                  passHref
                >
                  <IconButton
                    aria-label='instagram'
                    variant='ghost'
                    size='lg'
                    isRound={true}
                    _hover={{ bg: '#DCE2FF' }}
                    icon={<BsInstagram size='28px' />}
                  />
                </NextLink>
                <NextLink
                  href='https://www.fresha.com/a/maisie-beauty-coquitlam-3502-princeton-avenue-y6fltmj6?pId=660399'
                  passHref
                >
                  <IconButton
                    aria-label='fresha'
                    variant='ghost'
                    size='lg'
                    isRound={true}
                    _hover={{ bg: '#DCE2FF' }}
                    icon={<FaBars size='28px' />}
                  />
                </NextLink>
              </HStack>
            </Box>
          </WrapItem>
          <WrapItem>
            <Box
              bg='white'
              w={{ base: '84vw', md: 'full' }}
              borderRadius='lg'
              p={8}
              color='#0B0E3F'
            >
              <Formik
                initialValues={{ from_name: '', from_email: '', message: '' }}
                onSubmit={(values, actions) => {
                  setTimeout(() => {
                    sendEmail(values);
                    actions.setSubmitting(false);
                    actions.resetForm();
                  }, 1000);
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <VStack spacing={5}>
                      <Field
                        name='from_name'
                        validate={(v) => validateRequiredField('Name', v)}
                      >
                        {({ field, form }) => (
                          <FormControl
                            id='from_name'
                            isInvalid={
                              form.errors.from_name && form.touched.from_name
                            }
                          >
                            <FormLabel htmlFor='from_name'>
                              {t('contact.form.name')}
                            </FormLabel>
                            <InputGroup borderColor='#E0E1E7'>
                              <InputLeftElement pointerEvents='none'>
                                <BsPerson color='gray.800' />
                              </InputLeftElement>
                              <Input {...field} type='text' size='md' />
                            </InputGroup>
                            <FormErrorMessage>
                              {form.errors.from_name}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name='from_email' validate={validateEmail}>
                        {({ field, form }) => (
                          <FormControl
                            id='from_email'
                            isInvalid={
                              form.errors.from_email && form.touched.from_email
                            }
                          >
                            <FormLabel>{t('contact.form.mail')}</FormLabel>
                            <InputGroup borderColor='#E0E1E7'>
                              <InputLeftElement pointerEvents='none'>
                                <MdOutlineEmail color='gray.800' />
                              </InputLeftElement>
                              <Input
                                {...field}
                                type='email'
                                name='from_email'
                                size='md'
                              />
                            </InputGroup>
                            <FormErrorMessage>
                              {form.errors.from_email}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field
                        name='message'
                        validate={(v) => validateRequiredField('Message', v)}
                      >
                        {({ field, form }) => (
                          <FormControl
                            id='message'
                            isInvalid={
                              form.errors.message && form.touched.message
                            }
                          >
                            <FormLabel>{t('contact.form.message')}</FormLabel>
                            <Textarea
                              {...field}
                              name='message'
                              borderColor='gray.300'
                              _hover={{
                                borderRadius: 'gray.300',
                              }}
                              placeholder={t(
                                'contact.form.message'
                              ).toLowerCase()}
                            />
                            <FormErrorMessage>
                              {form.errors.message}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Box pt={4} w='full'>
                        <ReCAPTCHA
                          sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
                          onChange={(token) => setCaptchaToken(token)}
                        />
                      </Box>
                      <FormControl id='submitBtn'>
                        <Button
                          float='right'
                          variant='solid'
                          colorScheme='red'
                          type='submit'
                          value='Send'
                          disabled={!captchaToken || isSubmitting}
                        >
                          {t('contact.form.sendButton')}
                        </Button>
                      </FormControl>
                    </VStack>
                  </Form>
                )}
              </Formik>
            </Box>
          </WrapItem>
        </Wrap>
      </Box>
    </Box>
  );
};

export default ContactForm;
