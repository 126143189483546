import { service, membership } from 'utils/constants';

export const fr_FR = {
  // HEADER
  navbar: {
    homePage: 'Accueil',
    servicePage: 'Service',
    contactPage: 'Contact',
    protocolPage: 'Protocoles de santé et de sécurité',
    bookNowButton: 'Reserve maintenant',
  },
  // FEATURE
  featureSection: {
    header: 'Maisie Beauty Salon & Spa',
    subheader:
      "Chez Maisie Beauty à Coquitlam, en Colombie-Britannique, notre objectif est de créer une conformité entre l'état d'être interne et externe ; en d'autres termes, pour vous faire sentir en bonne santé et belle à l'intérieur comme à l'extérieur.",
    protocolButton: 'Mise à jour des protocoles de santé et de sécurité',
    title1: 'Professionnels certifiés de salon/spa',
    text1:
      "Notre équipe d'esthétique faciale certifiée est là pour prendre soin de vos besoins beauté. Nous veillerons à ce que vous obteniez les meilleurs services et que vous vous sentiez aussi relaxant et confortable que possible.",
    title2: 'Nos produits et services',
    text2:
      'Nous pratiquons les meilleurs services chez Maisie Beauty. Nous avons sélectionné des produits et des équipements de la plus haute qualité pour rendre vos services aussi rafraîchissants que possible.',
    title3: 'Adhésions et promotions',
    text3:
      "Nous impressionnons toujours nos clients avec des promotions intéressantes et les meilleurs avantages de l'adhésion ! Veuillez consulter notre page d'abonnements et de promotions pour voir ce que nous avons dans le salon pour vous!",
  },
  // SERVICE
  service: {
    header1: 'Le bon service pour',
    header2: 'Votre santé et beauté',
    subheader: 'Faites toujours de la place à une petite beauté dans votre vie',
    // 1: {
    //   name: 'Express Rose Facial',
    //   item: {
    //     1: 'Deep pore cleanse',
    //     2: 'Exfoliation',
    //     3: 'Steam',
    //     4: 'Extraction (if needed)',
    //     5: 'Mask',
    //     6: 'Scalp massage',
    //     7: 'Finish Product',
    //   },
    //   price: service.express.price,
    //   sale: service.express.sale,
    //   duration: ' - 30 minutes',
    // },
    // 2: {
    //   name: 'Premium Tulip Facial',
    //   item: {
    //     1: 'Extended Express Rose Facial to premium service',
    //     2: 'Premium Facial massage',
    //     3: 'Body electric massage chair',
    //   },
    //   price: service.premium.price,
    //   sale: service.premium.sale,
    //   duration: ' - 45 minutes',
    // },
    // 3: {
    //   name: 'Luxury Daisy Facial',
    //   item: {
    //     1: 'Extended Premium Tulip Facial to Luxury service',
    //     2: 'Head, neck and shoulder massage',
    //     3: 'Extended full body electric massage chair',
    //   },
    //   price: service.luxury.price,
    //   sale: service.luxury.sale,
    //   duration: ' - 65 minutes',
    // },
    // 4: {
    //   name: 'Signature Gua sha Facial',
    //   item: {
    //     1: 'Luxury Daisy Facial service',
    //     2: 'Gua sha Facial massage',
    //     3: 'Gua sha head, neck and shoulder massage',
    //   },
    //   price: service.signature.price,
    //   sale: service.signature.sale,
    //   duration: ' - 75 minutes',
    // },
    serviceCTA: 'Book Now',
  },
  // TESTIMONIAL
  testimonial: {
    heading: 'Nos clients parlent',
    subheading: 'Nous avons été avec des clients autour de la ville',
  },
  // MEMBERSHIP
  membership: {
    heading: 'Devenir membre VIP de Maisie Beauty',
    subheading:
      'Obtenez votre propre adhésion et recevez des réductions exclusives, des invitations à des événements, des offres partenaires et plus encore !',
    year: 'année',
    month: 'mois',
    card1: {
      heading: "Promotion d'ouverture",
      denote: 'Best Offer',
      price: membership.deal.price,
      item1:
        "Jusqu'à 45 % de réduction sur tous les services chez Maisie Beauty.",
      item2: 'Peut être utilisé sur plusieurs services à des jours différents.',
      item3: "La promotion n'est valable que sur une durée limitée.",
      buttonLabel: 'Reserve maintenant',
    },
    card2: {
      heading: 'Adhésion Deluxe VIP',
      denote: 'Le plus populaire',
      price: membership.deluxe.price,
      item1: 'Un massage facial Premium Tulip par mois.',
      item2: 'Un massage facial Express Rose par mois.',
      item3: 'Événements et offres spéciales exclusifs VIP.',
      buttonLabel: 'Acheter maintenant',
    },
    card3: {
      heading: 'Adhésion Starter VIP',
      denote: '',
      price: membership.starter.price,
      item1: 'Un massage facial signature Gua sha par mois.',
      item2: 'Un massage facial de luxe aux marguerites par mois.',
      item3: 'Événements et offres spéciales exclusifs VIP.',
      buttonLabel: 'Acheter maintenant',
    },
  },
  // CONTACT
  contact: {
    form: {
      heading: 'Rechercher et contacter',
      subheading: 'Remplissez le formulaire ci-dessous pour prendre contact',
      name: 'Votre nom',
      mail: 'Votre e-mail',
      message: 'Message',
      sendButton: 'Envoyer le message',
    },
  },
  // FOOTER
  footer: {
    legal: '© 2022 Maisie Beauty. All rights reserved',
  },
};
