import { ReactNode, useRef } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Container,
  Avatar,
  useColorModeValue,
  useBreakpointValue,
  ScaleFade,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useInViewport } from 'react-in-viewport';

const Testimonial = ({ children }: { children: ReactNode }) => {
  const ref = useRef(null);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const rootMargin = isMobile ? '0px' : '-200px';
  const { enterCount } = useInViewport(
    ref,
    { rootMargin },
    { disconnectOnLeave: false },
    {}
  );

  return (
    <ScaleFade initialScale={1} whileHover={{ scale: 1.1 }} in={enterCount > 0}>
      <Box ref={ref}>{children}</Box>
    </ScaleFade>
  );
};

const TestimonialContent = ({ children }: { children: ReactNode }) => {
  return (
    <Stack
      bg={useColorModeValue('brand.700', 'brand.1200')}
      boxShadow={'lg'}
      p={8}
      rounded={'xl'}
      align={'center'}
      pos={'relative'}
      _after={{
        content: `""`,
        w: 0,
        h: 0,
        borderLeft: 'solid transparent',
        borderLeftWidth: 16,
        borderRight: 'solid transparent',
        borderRightWidth: 16,
        borderTop: 'solid',
        borderTopWidth: 16,
        borderTopColor: useColorModeValue('brand.700', 'brand.1200'),
        pos: 'absolute',
        bottom: '-16px',
        left: '50%',
        transform: 'translateX(-50%)',
      }}
    >
      {children}
    </Stack>
  );
};

const TestimonialHeading = ({ children }: { children: ReactNode }) => {
  return (
    <Heading as={'h3'} fontSize={'xl'}>
      {children}
    </Heading>
  );
};

const TestimonialText = ({ children }: { children: ReactNode }) => {
  return (
    <Text
      textAlign={'center'}
      color={useColorModeValue('gray.600', 'gray.400')}
      fontSize={'sm'}
    >
      {children}
    </Text>
  );
};

const TestimonialAvatar = ({
  src,
  name,
  title,
}: {
  src?: string;
  name: string;
  title?: string;
}) => {
  return (
    <Flex align={'center'} mt={8} direction={'column'}>
      {src ? <Avatar src={src} name={name} mb={2} /> : null}
      <Stack spacing={-1} align={'center'}>
        <Text fontWeight={600}>{name}</Text>
        <Text fontSize={'sm'} color={useColorModeValue('gray.600', 'gray.400')}>
          {title}
        </Text>
      </Stack>
    </Flex>
  );
};

const TestimonialSection = () => {
  const { t } = useTranslation();

  return (
    <Box bg={useColorModeValue('brand.300', 'brand.1100')}>
      <Container maxW={'7xl'} py={16} as={Stack} spacing={12}>
        <Stack spacing={0} align={'center'}>
          <Heading>{t('testimonial.heading')}</Heading>
          <Text>{t('testimonial.subheading')}</Text>
        </Stack>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 10, md: 4, lg: 10 }}
        >
          <Testimonial>
            <TestimonialContent>
              {/* <TestimonialHeading>Efficient Collaborating</TestimonialHeading> */}
              <TestimonialText>
                What a great experience. My skin feels so good and my face looks
                so relaxed. Lovely room with relaxing music, great massage
                lounge and air conditioning. Amazing value. I will be returning
                and telling my friends. So nice to be able to support a local
                independent business.
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              // src={
              //   'https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=100&q=80'
              // }
              name={'Marianne LaRochelle'}
              // title={'CEO at ABC Corporation'}
            />
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              {/* <TestimonialHeading>Intuitive Design</TestimonialHeading> */}
              <TestimonialText>
                Absolutely wonderful experience. My skin feels fantastic and my
                face looks so relaxed. Lovely room with soothing music and air
                conditioning. The massage lounge was a plus.
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              // src={
              //   'https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=100&q=80'
              // }
              name={'Ms. ML'}
              // title={'CEO at ABC Corporation'}
            />
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              {/* <TestimonialHeading>Mindblowing Service</TestimonialHeading> */}
              <TestimonialText>
                Cindy did a great job on facial massage! I was looking for a
                facial salon near home to be relaxed after a long week. At
                Maisie Beauty, I got EXACTLY what I wanted. Recommend and will
                come back!
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              // src={
              //   'https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=100&q=80'
              // }
              name={'Jane C'}
              // title={'CEO at ABC Corporation'}
            />
          </Testimonial>
        </Stack>
      </Container>
    </Box>
  );
};

export default TestimonialSection;
