import { service, membership } from 'utils/constants';

export const en_US = {
  // HEADER
  navbar: {
    homePage: 'Home',
    servicePage: 'Service',
    contactPage: 'Contact',
    protocolPage: 'Health and Safety Protocols',
    bookNowButton: 'Book Now',
  },
  // FEATURE
  featureSection: {
    header: 'Maisie Beauty Salon & Spa',
    subheader:
      'At Maisie Beauty in Coquitlam, BC, our goal is to create conformity between the internal and external state of being; in other words, to make you feel healthy and beautiful inside and out.',
    protocolButton: 'Health and Safety Protocols Update',
    title1: 'Certified Salon/Spa Professionals',
    text1:
      'Our team of certified facial esthetics are here to take care of your beauty needs. We will ensure you get the best services and feel relaxing and comfortable as possible.',
    title2: 'Our Products and Services',
    text2:
      'We practices the best services at Maisie Beauty. We have selected the highest quality products and equipments to make your services as refreshing as possible.',
    title3: 'Memberships and Promotions',
    text3:
      'We are always impressing our clients with exciting promotions and top membership benefits! Please check out our memberships and promotions page to see what we have in the salon for you!',
  },
  // SERVICE
  service: {
    header1: 'The Right Service for',
    header2: 'Your Health & Beauty',
    subheader: 'Always Make Room for a Little Beauty in Your Life',
    1: {
      name: 'Express Rose Facial',
      item: {
        1: 'Deep pore cleanse',
        2: 'Exfoliation',
        3: 'Steam',
        4: 'Extraction (if needed)',
        5: 'Mask',
        6: 'Scalp massage',
        7: 'Finish Product',
      },
      price: service.express.price,
      sale: service.express.sale,
      duration: '30 minutes',
    },
    2: {
      name: 'Premium Tulip Facial',
      item: {
        1: 'Extended Express Rose Facial to premium service',
        2: 'Premium Facial massage',
        3: 'Body electric massage chair',
      },
      price: service.premium.price,
      sale: service.premium.sale,
      duration: '45 minutes',
    },
    3: {
      name: 'Luxury Daisy Facial',
      item: {
        1: 'Extended Premium Tulip Facial to Luxury service',
        2: 'Head, neck and shoulder massage',
        3: 'Extended full body electric massage chair',
      },
      price: service.luxury.price,
      sale: service.luxury.sale,
      duration: '65 minutes',
    },
    4: {
      name: 'Signature Gua sha Facial',
      item: {
        1: 'Luxury Daisy Facial service',
        2: 'Gua sha Facial massage',
        3: 'Gua sha head, neck and shoulder massage',
      },
      price: service.signature.price,
      sale: service.signature.sale,
      duration: '75 minutes',
    },
    serviceCTA: 'Book Now',
  },
  // TESTIMONIAL
  testimonial: {
    heading: 'Our Clients Speak',
    subheading: 'We have been with customers around town',
  },
  // MEMBERSHIP
  membership: {
    heading: 'Become a Maisie Beauty VIP Member',
    subheading:
      'Get your very own membership and receive exclusive discount, event invites, partner offers and more!',
    year: 'year',
    month: 'month',
    card1: {
      heading: 'Holiday Season Promotion',
      denote: 'Best Offer',
      price: membership.deal.price,
      item1: '25% discount on all services at Maisie Beauty.',
      item2: 'Promotion can be redeemed at Point of Sale.',
      item3: 'Promotion starts on 15th November 2022 and ends on 8th January 2023.',
      buttonLabel: 'Book Now',
    },
    card2: {
      heading: 'Deluxe VIP Membership',
      denote: 'Most Popular',
      price: membership.deluxe.price,
      item1: 'One Signature Gua sha Facial Massage per month.',
      item2: 'One Luxury Daisy Facial Massage per month.',
      item3: 'VIP Exclusive events & specials.',
      buttonLabel: 'Buy Now',
    },
    card3: {
      heading: 'Starter VIP Membership',
      denote: '',
      price: membership.starter.price,
      item1: 'One Premium Tulip Facial Massage per month.',
      item2: 'One Express Rose Facial Massage per month.',
      item3: 'VIP Exclusive events & specials.',
      // item3: 'Save the taxes (12% off) on all products (in-spa purchase).',
      buttonLabel: 'Buy Now',
    },
  },
  // CONTACT
  contact: {
    form: {
      heading: 'Find & Contact',
      subheading: 'Fill up the form below to contact',
      name: 'Your Name',
      mail: 'Your Email',
      message: 'Message',
      sendButton: 'Send Message',
    },
  },
  // FOOTER
  footer: {
    legal: '© 2022 Maisie Beauty. All rights reserved',
  },
};
