import {
  Box,
  Stack,
  Flex,
  HStack,
  IconButton,
  Button,
  Text,
  Center,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
import { GrMenu, GrClose } from 'react-icons/gr';
import NextLink from 'next/link';
import { useTranslation } from 'react-i18next';
import Logo from './Logo';

const Header = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const Links = [
    { title: t('navbar.homePage'), path: '/' },
    { title: t('navbar.servicePage'), path: '/service' },
    { title: t('navbar.contactPage'), path: '/contact' },
    { title: t('navbar.protocolPage'), path: '/health-and-safety-protocols' },
  ];
  const headerBg = useColorModeValue(
    'rgba(255, 255, 255, 0.5)',
    'rgba(0, 0, 0, 0.5)'
  );
  const menuTextBg = useColorModeValue('gray.200', 'gray.700');

  return (
    <Box bg={useColorModeValue('gray.100', 'gray.900')}>
      <Flex
        h={16}
        w='100%'
        alignItems={'center'}
        justifyContent={'space-between'}
        as='header'
        position='fixed'
        backgroundColor={useColorModeValue(
          'rgba(255, 255, 255, 0.7)',
          'rgba(0,0,0, 0.7)'
        )}
        backdropFilter='saturate(180%) blur(5px)'
        px={4}
        zIndex={200}
      >
        <IconButton
          size={'md'}
          icon={<Center>{isOpen ? <GrClose /> : <GrMenu />}</Center>}
          aria-label={'Open Menu'}
          colorScheme='brand'
          display={{ md: 'none' }}
          onClick={isOpen ? onClose : onOpen}
        />
        <HStack spacing={8} alignItems={'center'}>
          <Logo />
          <HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }}>
            {Links.map((link, index) => (
              <NextLink key={index.toString()} passHref href={link.path}>
                <Button variant='transparent' px={4} py={2}>
                  <Text fontSize='lg'>{link.title}</Text>
                </Button>
              </NextLink>
            ))}
          </HStack>
        </HStack>
        <Flex alignItems={'center'}>
          <NextLink
            href='https://www.fresha.com/book-now/maisie-beauty-wxun6ef8/services?lid=703267&eid=1878051&pId=660399'
            passHref
          >
            <Button colorScheme='red' size='md' mr={{ base: 0, md: 4 }}>
              {t('navbar.bookNowButton')}
            </Button>
          </NextLink>
        </Flex>
      </Flex>

      {isOpen ? (
        <Box
          pb={4}
          display={{ md: 'none' }}
          mt={16}
          w='100%'
          alignItems={'center'}
          justifyContent={'space-between'}
          position='fixed'
          backgroundColor={headerBg}
          backdropFilter='saturate(180%) blur(5px)'
          px={4}
          zIndex={200}
        >
          <Stack
            as={'nav'}
            spacing={2}
            mt={2}
            onClick={isOpen ? onClose : onOpen}
          >
            {Links.map((link, index) => (
              <NextLink key={index.toString()} passHref href={link.path}>
                <Text
                  fontSize='xl'
                  p={4}
                  _hover={{
                    textDecoration: 'none',
                    bg: menuTextBg,
                  }}
                >
                  {link.title}
                </Text>
              </NextLink>
            ))}
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
};

export default Header;
