import { ChakraProvider } from '@chakra-ui/react';
import Head from 'next/head';
import { DefaultSeo } from 'next-seo';
import { AnimatePresence } from 'framer-motion';
import { AppProps } from 'next/app';

import 'i18n/i18n';
import theme from 'src/theme/theme';
import Fonts from 'src/theme/fonts';
import { Header, Footer } from 'components';
import '../theme/styles.css';

function MyApp({ Component, pageProps, router }: AppProps) {
  const url = `https://maisiebeauty.com${router.route}`;

  return (
    <ChakraProvider resetCSS theme={theme}>
      <Fonts />
      <Head>
        <link rel='shortcut icon' href='/favicon.ico' />
      </Head>
      <DefaultSeo
        titleTemplate='%s - Maisie Beauty'
        description='Maisie Beauty Spa Salon, Vancouver, Canada'
        openGraph={{
          type: 'website',
          locale: 'en_IE',
          url,
          description: 'Maisie Beauty Spa Salon, Vancouver, Canada',
          site_name: 'Maisie Beauty | maisiebeauty.com',
          images: [
            {
              url: '/public/images/logo.png',
              width: 496,
              height: 128,
              alt: 'Maisie Beauty Logo',
            },
            {
              url: '/public/images/hero2.png',
              width: 1200,
              height: 1200,
              alt: 'Maisie Beauty Hero',
            },
          ],
        }}
        canonical={url}
      />
      <Header />
      <AnimatePresence
        exitBeforeEnter
        initial={false}
        onExitComplete={() => window.scrollTo(0, 0)}
      >
        <Component {...pageProps} canonical={url} key={url} />
      </AnimatePresence>
      <Footer />
    </ChakraProvider>
  );
}

export default MyApp;
