import React, { PropsWithChildren } from 'react';
import { NextSeo } from 'next-seo';
import { motion } from 'framer-motion';
import { Box, Flex } from '@chakra-ui/react';
import { LanguageSwitch, DarkModeSwitch } from './';

type Props = PropsWithChildren<{
  title: string;
  description: string;
}>;

const variants = {
  hidden: { opacity: 0, x: -200, y: 0 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: 0, y: -100 },
};

const Layout = ({ children, title, description }: Props): JSX.Element => (
  <div>
    <NextSeo
      title={title}
      description={description}
      openGraph={{
        title,
        description,
        url: 'https://www.maisiebeauty.com',
        locale: 'en_US',
      }}
    />
    <motion.main
      initial='hidden'
      animate='enter'
      exit='exit'
      variants={variants}
      transition={{ type: 'linear' }}
      className='
      flex flex-col items-start w-full pt-10
      px-8 sm:px-16 md:px-36 lg:px-52 xl:px-80 2xl:px-96
      pt-24 h-full
      '
    >
      <Flex
        direction='column'
        alignItems='center'
        justifyContent='flex-start'
        transition='all 0.15s ease-out'
      >
        {children}
        <Box position='fixed' bottom={4} right={4}>
          <LanguageSwitch />
          <DarkModeSwitch />
        </Box>
      </Flex>
    </motion.main>
  </div>
);

export default Layout;
