import {
  Box,
  Button,
  Divider,
  Heading,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import NextLink from 'next/link';
import { freshaLink, service } from 'utils/constants';

interface ServiceProps {
  title: string;
  options: Array<{ id: number; desc: string }>;
  price?: string;
  sale?: string;
  duration: string;
  link: string;
  buttonLabel: string;
  checked?: boolean;
}
const Service = ({
  title,
  options,
  price,
  sale,
  duration,
  link,
  buttonLabel,
  checked = false,
}: ServiceProps) => {
  const checkMarkColor = useColorModeValue('red.500', 'yellow.500');
  const TypePlan = () => {
    return sale ? (
      <Stack alignItems='center'>
        {price && sale ? (
          <Text>
            <s>$ {price}</s> <span style={{ marginLeft: '1rem' }}>${sale}</span>
          </Text>
        ) : (
          <Text>${sale}</Text>
        )}
        <Text>{duration}</Text>
      </Stack>
    ) : (
      <Stack alignItems='center'>
        <Text>{price}</Text>
        <Text>{duration}</Text>
      </Stack>
    );
  };

  return (
    <Stack
      p={{ base: 2, md: 16 }}
      py={4}
      justifyContent={{
        base: 'flex-start',
        md: 'space-between',
      }}
      spacing={3}
      direction={{
        base: 'column',
        md: 'row',
      }}
      alignItems={{ md: 'center' }}
    >
      <Heading w={{ base: 'full', md: '15rem' }} size={'md'}>
        {title}
      </Heading>
      <List w={{ base: 'full', md: '30rem' }} spacing={3} textAlign='start'>
        {options.map((desc, id) => (
          <ListItem key={desc.id}>
            <ListIcon as={FaCheckCircle} color={checkMarkColor} />
            {desc.desc}
          </ListItem>
        ))}
      </List>
      <Heading w={{ base: 'full', md: '25rem' }} size={'xl'}>
        <TypePlan />
      </Heading>
      <Stack w={{ base: 'full', md: '10rem' }}>
        <NextLink passHref href={link}>
          <Button size='md' colorScheme='red'>
            {buttonLabel}
          </Button>
        </NextLink>
      </Stack>
    </Stack>
  );
};

const ServiceSection = () => {
  const { t } = useTranslation();
  const services = [
    {
      title: t('service.1.name'),
      // price: t('service.1.price'),
      // sale: t('service.1.sale'),
      duration: t('service.1.duration'),
      items: [
        { id: 1, desc: t('service.1.item.1') },
        { id: 2, desc: t('service.1.item.2') },
        { id: 3, desc: t('service.1.item.3') },
        { id: 4, desc: t('service.1.item.4') },
        { id: 5, desc: t('service.1.item.5') },
        { id: 6, desc: t('service.1.item.6') },
        { id: 7, desc: t('service.1.item.7') },
      ],
      link: freshaLink.expressFacial,
    },
    {
      title: t('service.2.name'),
      // price: t('service.2.price'),
      // sale: t('service.2.sale'),
      duration: t('service.2.duration'),
      items: [
        { id: 1, desc: t('service.2.item.1') },
        { id: 2, desc: t('service.2.item.2') },
        { id: 3, desc: t('service.2.item.3') },
      ],
      link: freshaLink.premiumFacial,
    },
    {
      title: t('service.3.name'),
      // price: t('service.3.price'),
      // sale: t('service.3.sale'),
      duration: t('service.3.duration'),
      items: [
        { id: 1, desc: t('service.3.item.1') },
        { id: 2, desc: t('service.3.item.2') },
        { id: 3, desc: t('service.3.item.3') },
      ],
      link: freshaLink.luxuryFacial,
    },
    {
      title: t('service.4.name'),
      // price: t('service.4.price'),
      // sale: t('service.4.sale'),
      duration: t('service.4.duration'),
      items: [
        { id: 1, desc: t('service.4.item.1') },
        { id: 2, desc: t('service.4.item.2') },
        { id: 3, desc: t('service.4.item.3') },
      ],
      link: freshaLink.guaShaFacial,
    },
  ];

  return (
    <Box py={6} px={5}>
      <Stack spacing={4} width={'100%'} direction={'column'}>
        <Stack
          p={5}
          alignItems={'center'}
          justifyContent={{
            base: 'flex-start',
            md: 'space-around',
          }}
          direction={{
            base: 'column',
            md: 'row',
          }}
        >
          <Stack
            width={{
              base: '100%',
              md: '40%',
            }}
            textAlign={'center'}
          >
            <Heading size={'lg'}>
              {t('service.header1')}{' '}
              <Text color='red.500'>{t('service.header2')}</Text>
            </Heading>
          </Stack>
          <Stack
            width={{
              base: '100%',
              md: '50%',
            }}
          >
            <Text textAlign={'center'}>{t('service.subheader')}</Text>
          </Stack>
          <Stack
            width={{
              base: '100%',
              md: '10%',
            }}
          >
            <NextLink
              passHref
              href='https://www.fresha.com/book-now/maisie-beauty-wxun6ef8/services?lid=703267&eid=1878051&pId=660399'
            >
              <Button size='md' colorScheme='red'>
                All Our services
              </Button>
            </NextLink>
          </Stack>
        </Stack>
        {services.map((service, index) => (
          <Box key={index.toString()}>
            <Divider />
            <Service
              title={service.title}
              // price={service.price}
              // sale={service.sale}
              duration={service.duration}
              options={service.items}
              link={service.link}
              buttonLabel={t('service.serviceCTA')}
              checked
            />
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default ServiceSection;
