import { ScaleFade } from '@chakra-ui/react';
import NextLink from 'next/link';
import Image from 'next/image';

const Logo = () => (
  <ScaleFade initialScale={1} whileHover={{ scale: 1.1 }} in={true}>
    <NextLink href='/' passHref>
      <a>
        <Image
          src='/images/logo.png'
          alt='Maisie Beauty'
          width={193.75}
          height={50}
        />
      </a>
    </NextLink>
  </ScaleFade>
);

export default Logo;
